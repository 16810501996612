<template>
  <article class="menu">
    <p>
      <router-link :to="`/${$route.params.id}`">Tillbaka</router-link>
    </p>
    <h1 class="mb-0">菜單</h1>
    <h2 class="mt-0">Take away-meny</h2>
    <p class="mb-3">
      Ring och beställ:
      <a class="accent bold" href="tel:08888576">08 &mdash; 88 85 76</a>
    </p>

    <div class="menu-category">
      <menu-item
        v-for="(item, index) in this.items"
        :key="index"
        :item="item"
      />
    </div>
  </article>
</template>

<style lang="scss" scoped>
h1 {
  font-size: 2rem;
  font-weight: 700;
}
h1 + h2 {
  font-size: 1rem;
}

.text-vertical {
  writing-mode: vertical-lr;
  text-orientation: upright;
}

.menu {
  flex-direction: column;
  align-items: flex-start;
}
</style>

<script>
import MenuItem from "../components/MenuItem.vue";

const locations = {
  malmo: [],
  stockholm: [
    {
      name: {
        sv: "Krispig gurka, soja, vinäger, chili, sesam",
        zh: "拍黄瓜 veg",
        en: ""
      },
      price: 80,
      quantity: 0
    },
    {
      name: {
        sv: "Friterade wontons, scampifärs, sötsyrlig sås",
        zh: "餛飩",
        en: ""
      },
      price: 135,
      quantity: 0
    },
    {
      name: {
        sv: "Dumplings, skinkfärs, kål, ingefära, soja-chilivinäger",
        zh: "鍋貼",
        en: ""
      },
      price: 160,
      quantity: 8
    },
    {
      name: {
        sv: "Ryggbiff, brytbönor, fermenterad svartböna. Inkl ris.",
        zh: "炒豆牛肉",
        en: ""
      },
      price: 180,
      quantity: 0
    },
    {
      name: {
        sv: "Stekt lax, rostad salladslök och ingefära, sötsoja. Inkl ris.",
        zh: "煎三文鱼",
        en: ""
      },
      price: 180,
      quantity: 0
    },
    {
      name: {
        sv: "Kantonesisk grillad anka med pak choy och ris.",
        zh: "燒味",
        en: ""
      },
      price: 170,
      quantity: 0
    },
    {
      name: {
        sv: "Krispigt grillad sidfläsk med pak choy och ris.",
        zh: "燒味",
        en: ""
      },
      price: 165,
      quantity: 0
    },
    {
      name: {
        sv: "Wokade vetenudlar, ryggbiff, purjolök och böngroddar",
        zh: "干炒牛河",
        en: ""
      },
      price: 180,
      quantity: 0
    },
    {
      name: {
        sv:
          "Vegansk Mapo Tofu, silkeslen tofu, sojafärs, tomat, chili. Inkl ris.",
        zh: "麻婆豆腐 veg",
        en: ""
      },
      price: 145,
      quantity: 0
    },
    {
      name: {
        sv: "Kailan (kinesisk broccoli), vitlök, ostronsås. Inkl ris.",
        zh: "時菜香 veg",
        en: ""
      },
      price: 155,
      quantity: 0
    }
  ]
};

export default {
  components: { MenuItem },
  data: () => ({
    id: null,
    items: []
  }),
  created() {
    this.id = this.$route.params.id;
    this.items = locations[this.id] ?? [];
  }
};
</script>
